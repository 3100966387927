import useAuth from 'hooks/authentication/useAuth'
import Link from 'next/link'
import NotFoundTemplate from 'shared/components/layout/NotFoundTemplate'
import Metadata from 'shared/components/Metadata'

const NotFoundPage = () => {
  const { isLoggedIn } = useAuth()

  return (
    <>
      <Metadata url="/404" preloadImageUrls={['/images/404-banner.webp']} />
      <NotFoundTemplate>
        <div className="flex flex-col items-center gap-8 py-10">
          <h1 className="text-center text-[40px] leading-[50px] md:w-[800px] md:text-[60px] md:leading-[60px]">
            The page you are looking for cannot be found
          </h1>
          <Link
            href={isLoggedIn ? '/homepage' : '/'}
            className="rounded-full bg-[#1A1A1A] px-4 py-[14px] text-xs text-white md:py-4 md:text-base"
          >
            {isLoggedIn ? 'Return to Homepage' : 'Continue Browsing'}
          </Link>
        </div>
      </NotFoundTemplate>
    </>
  )
}

export default NotFoundPage
